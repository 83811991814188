<template>
    <div class="location">
        <div class="location-header">
            <BackOnTop />
            <h2>門市據點</h2>
            <h4>共有{{ locationList.length }}家分店</h4>
        </div>
        <div class="location_list">
            <LocationColumn
                v-for="el in locationList"
                :key="el.shop"
                :shopData="el"
            />
        </div>
        <div class="service">
            <ul>
                <li v-if="false" class="d_flex">
                    <div class="f_white">
                        <span>客服專線</span>
                        <span>0800-678-222</span>
                    </div>
                    <a href="tel:+0800-678-222" class="phone">立即撥打</a>
                </li>
                <li class="d_flex">
                    <div class="f_white">
                        <span>客服信箱</span>
                        <span>service@greattreepets.com</span>
                    </div>
                    <a href="mailto:service@greattreepets.com" class="mail"
                        >聯繫客服</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import BackOnTop from '@/components/BackOnTop.vue';
import LocationColumn from '@/components/LocationColumn.vue';
import { getStore } from '@/apis/api.js';
export default {
    name: 'ToolIndex',
    components: { BackOnTop, LocationColumn },
    created() {
        var parent = this;
        getStore().then(function (rsp) {
            if (rsp) {
                console.log(rsp);
                parent.locationList = [];
                for (let i in rsp) {
                    if (rsp[i].is_useful) {
                        parent.locationList.push({
                            shop: rsp[i].name,
                            address: rsp[i].address,
                            open: rsp[i].business_hours,
                            phone: rsp[i].phone,
                            id: rsp[i].id,
                        });
                    }
                }
            } else {
                console.log('getStore error');
            }
        });
    },
    data: () => ({
        locationList: [
            // {
            //     shop: '內壢成章店',
            //     address: '桃園市中壢區成章一街162號',
            //     open: '09:00-22:00',
            //     phone: '03-4335200',
            //     id: '123',
            // },
            // {
            //     shop: '八德興豐店',
            //     address: '桃園市八德區興豐路579號',
            //     open: '09:00-22:00',
            //     phone: '03-3688851',
            //     id: '123',
            // },
        ],
    }),
    beforeCreate() {
        this.$store.state.isLoading = true;
    },
    mounted() {
        this.$nextTick(() => {
            this.$store.state.isLoading = false;
        });
    },
};
</script>
<style lang="scss" scoped>
.location {
    position: relative;
    min-height: 100vh;
    padding-bottom: 220px;
    h2 {
        color: $color_main_first;
        font-size: 26px;
        font-weight: bold;
        padding: 12px 16px;
        line-height: 1;
        text-align: left;
    }
    h4 {
        font-size: 14px;
        line-height: 16px;
        color: $color_main_first;
        text-align: left;
        padding: 0 16px;
    }
    .location_list {
        margin-top: 24px;
        padding: 0 16px;
    }
    .location-header {
        padding-bottom: 20px;
        border-bottom: 1px solid #cccccc;
    }
}
.service {
    background-color: $color_main_first;
    padding: 0 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    ul {
        list-style: none;
    }

    li {
        padding: 16px 0;
        border-bottom: 1px solid #fff;
        text-align: left;
        justify-content: space-between;
        align-items: center;
        &:last-child {
            border: none;
        }
        .f_white {
            span {
                width: 100%;
                display: block;
            }
        }
        a {
            background-color: #fff;
            color: $color_main_first;
            border-radius: 30px;
            line-height: 35px;
            padding: 0 14px 0 38px;
            background-repeat: no-repeat;
            background-position: left 12px center;
            &.phone {
                background-position: left 10px center;
                background-image: url('~@/assets/images/ic24Phone.svg');
            }
            &.mail {
                background-image: url('~@/assets/images/ic24Email.svg');
            }
        }
    }
}
</style>
